import React from "react";
import { Link } from "react-router-dom";

const Dashboard = () => {
  return (
    <>
      <Link to={"/text-recognition"}>
        <button>Text Recognition</button>        
      </Link>
      <Link to={"/piratext"}>
        <button>PiraText</button>
      </Link>
      {/* <button>Language Detection</button> */}
    </>
  )
}

export default Dashboard;