import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAMNNC_stk8oGcHaUKIxc-S47wockx0xYM",
  authDomain: "piratext-piratecara.firebaseapp.com",
  projectId: "piratext-piratecara",
  // storageBucket: "piratext-piratecara.appspot.com",
  // messagingSenderId: "456735986486",
  appId: "1:456735986486:web:d6e062af754e662ce79997"
};
// Initialize Firebase
let firebaseapp = firebase.initializeApp(firebaseConfig);

export {firebaseapp, firebase};