import axios from "../utils/axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import baseUrl from "../utils/constants";

const SpeechToText = () => {

  const history = useHistory();

  const [data, setData] = useState([])
  const getAll = async () => {
    const data = await axios.get(`${baseUrl}/all`)
    setData(data.data)
  };
  useEffect(() => {
    getAll();
  }, [])

  const handleClick = (id) => {
    history.push(`/piratext/${id}`);
  }
  console.log(data)

  return (
    <>
      {data && data.length === 0 ?
        "No Data"
        :
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", padding: "2rem", gap: "50px", flexWrap: "wrap" }}>
          {data.map((item, i) => (
            <div key={i} style={{ width: "400px", height: "230px", cursor: "pointer", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
              <div style={{ padding: '1rem 2rem' }} onClick={() => handleClick(item._id)}>
                <p>{item.text && item.text.slice(0, 50)}</p>
                <h4>{item.creator && item.creator}</h4>
                <h4>{item.createdAt && item.createdAt.replace(" GMT+0530 (India Standard Time)", " ")}</h4>
                <h4>{item.LastUpdated && item.LastUpdated.replace(" GMT+0530 (India Standard Time)", " ")}</h4>
              </div>
            </div>
          ))}
        </div>
      }
    </>
  )
}

export default SpeechToText;