import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { LoginPage } from "./pages/Login";
import Home from "./pages/Home";
import AuthGaurd from "./components/AuthGaurd";
import Dashboard from "./pages/Dashboard";
import SpeechToText from "./components/SpeechToText";
import PiraText from "./components/Piratext";
import Header from "./components/Headers";
// import Temp from "./components/Temp";

export default function MainRoutes() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <AuthGaurd>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/dashboard" exact>
            <Dashboard />
          </Route>
          <Route path="/text-recognition" exact>
            <SpeechToText />
          </Route>
          <Route path="/piratext/:id" exact>
            <PiraText />
          </Route>
        </AuthGaurd>
      </Switch>
    </Router>
  )
}
