import React, { useEffect, useRef, useState } from 'react';
import axios from "../utils/axios";
import Editor from "@monaco-editor/react";
import { useParams } from 'react-router';
import baseUrl from '../utils/constants';

const PiraText = () => {

  let [text, setText] = useState("");
  let [data, setData] = useState({});
  const editorRef = useRef(null);
  const { id } = useParams();

  function emitEditorValue(value, event) {
    setText(value);
  }

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  const getOne = async () => {
    const data = await axios.get(`${baseUrl}/${id}`);
    setData(data.data.data)
  };

  useEffect(() => {
    getOne();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setText(data.text)
    // eslint-disable-next-line
  }, [data])

  const handleSave = async () => {
    // axios.post(`${baseUrl}/save`, {
    // script: editorRef.current.getValue(),
    // });
    console.log(editorRef.current.getValue())
    await axios.patch(`${baseUrl}/edit/${id}`, {
      text: editorRef.current.getValue()
    });

  };
  
  console.log(data)
  return (
    <>
      <div style={{ height: "80vh", width: "100%" }}>
        <Editor
          defaultValue={data.text}
          value={text}
          onChange={emitEditorValue}
          defaultLanguage={"javascript"}
          language={"javascript"}
          onMount={handleEditorDidMount}
          options={{ theme: 'vs-dark', }}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 5rem" }}>
        <p>Created At: {data?.createdAt && data.createdAt.replace(" GMT+0530 (India Standard Time)", " ")}</p>
        <button 
          onClick={handleSave} 
          style={{ outline: "none", border: "none", padding: "0.5rem 1.5rem", backgroundColor: "#0f0", color: "#f0f0f0", fontSize: "1rem", fontWeight: "600", cursor: "pointer" }}
        >Save</button>
        <p><i>Last Updated: {data?.LastUpdated && data.LastUpdated.replace(" GMT+0530 (India Standard Time)", " ")}</i></p>
      </div>
    </>
  )
}

export default PiraText;