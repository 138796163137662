import './App.css';
import { firebaseapp, firebase } from "./utils/firebase";
import React, { useEffect, useState } from 'react';
import MainRoutes from "./Routes";
import axios from "./utils/axios";

let UserContext = React.createContext();

// let userContext = Con
function App() {

  const [user, setUser] = useState();

  let statecallback = function (newUserState) {
    setUser(newUserState);
    if (newUserState) {
      firebaseapp
        .auth()
        .currentUser.getIdToken()
        .then(function (token) {
          axios.defaults.headers["Authorization"] = `Bearer ${token}`;
          console.log("token ", token)
        });
    } else {
      axios.defaults.headers["Authorization"] = "";
    }
  };

  useEffect(function () {
    firebase.auth().onAuthStateChanged(statecallback)
    // eslint-disable-next-line
  }, []);

  return (
    <div >
      <UserContext.Provider value={{ user, setUser }}>
        <MainRoutes />
      </UserContext.Provider>
    </div>
  );
}

export { App, UserContext };
