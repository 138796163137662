import { Link } from 'react-router-dom';

// eslint-disable-next-line
export default function() {
  
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", minHeight: "80vh" }}>
      <Link to={"/text-recognition"}>
        <button 
          style={{
            cursor: "pointer",
            padding: "1rem 2.5rem", 
            fontSize: "1.2rem", 
            backgroundColor: "green",
            marginTop: "1rem",
            outline: "none", 
            border: "none",
            borderRadius: "1rem", 
            color: "#fff"
          }}
        >
          Navigate to Application
        </button>
      </Link>
    </div>
  )
}