import React from "react";
import { firebase } from '../utils/firebase';
import { LoginPage } from "../pages/Login";
import { useContext } from "react";
import { UserContext } from "../App";
import { Link } from "react-router-dom";

const Header = () => {
    let { user } = useContext(UserContext);

    return (
        <div style={{ width: "100%", backgroundColor: "#121930" }}>
            <div style={{ padding: "0 24px", display: "flex", justifyContent: "space-between" }}>
                <div style={{ padding: "1em 0", display: "flex", alignItems: "center", fontSize: "1.5rem", fontWeight: "700", color: "#fff" }}><Link to="/" style={{ color: "inherit", textDecoration: "none" }} >PiraText</Link></div>
                <div style={{ display: "flex", alignItems: "center", gap: "1.8rem", padding: "1em 0" }}>
                    {(user === undefined || user === null) && <LoginPage />}
                    {user && (
                        <>
                            {user.displayName && (
                                <h1
                                    title={`${user.email && user.email} ${user.phoneNumber !== null ? user.phoneNumber : ""}`}
                                    style={{ color: "#fff", margin: "0", fontSize: "1.5rem" }}
                                >
                                    Hello, {user.displayName}
                                </h1>
                            )}
                            <div style={{ cursor: "pointer", color: "#fff", fontSize: "1.5rem", fontWeight: "500" }} onClick={() => firebase.auth().signOut()} >Logout</div>
                            {user.photoURL && (
                                <div style={{ height: "60px", width: "60px" }}>
                                    <img
                                        src={user.photoURL}
                                        alt={user.displayName}
                                        style={{ borderRadius: "50%", height: "100%", width: "100%" }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>

            </div>
        </div>
    )
}

export default Header;